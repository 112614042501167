import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link } from "gatsby";

import Button from "../../components/custom-widgets/button";
import Icon from "../custom-widgets/icon";

// Styles
import styles from "./savings-overview.module.scss";

const BusinessSavingsOverview = () => {
  const [collapseClass1, setCollapseClass1] = useState(false);
  const [collapseClass2, setCollapseClass2] = useState(false);
  const [collapseClass3, setCollapseClass3] = useState(false);

  const intereseRatesBtnData = {
    containerClass: "text-md-center",
    type: "link",
    class: "btn-link mb-3 mb-md-0 w-100 w-sm-auto w-md-100",
    text: "Business Interest Rates/APY",
    url: "/business-banking/business-cd-money-market-rates",
    showIcon: false
  };

  const bizPremiumMoneyMarket = {
    containerClass: "mb-4 mb-md-0",
    externalUrl: true,
    target: "blank",
    class: "btn-primary w-100 w-sm-auto w-md-100",
    text: "Open an Account",
    url: "https://wafd.my.site.com/nPortal__PortalLogin?startURL=%2fnportal__portal%3Fapp%3Dcustomer-portal&core=es5",
    showIcon: false
  };

  const bizMoneyMarket = {
    containerClass: "mb-4 mb-md-0",
    externalUrl: true,
    target: "blank",
    class: "btn-primary w-100 w-sm-auto w-md-100",
    text: "Open an Account",
    url: "https://wafd.my.site.com/nPortal__PortalLogin?startURL=%2fnportal__portal%3Fapp%3Dcustomer-portal&core=es5",
    showIcon: false
  };

  const bizSavings = {
    containerClass: "mb-4 mb-md-0",
    externalUrl: true,
    target: "blank",
    class: "btn-primary w-100 w-sm-auto w-md-100",
    text: "Open an Account",
    url: "https://wafd.my.site.com/nPortal__PortalLogin?startURL=%2fnportal__portal%3Fapp%3Dcustomer-portal&core=es5",
    showIcon: false
  };

  return (
    <section id="business-savings-overview-section" className="container">
      {/* HIDDEN XS & SM */}
      <div className="d-none d-md-block">
        <div className="row">
          <div className="col">
            <span className="sr-only">Compare business savings account details table</span>
            <table id="savings-overview-table" className="table table-bordered table-fixed mb-0">
              <thead className="bg-info font-weight-bold">
                <tr className="text-center">
                  {/* position: relative has been added prevent stretched-link from covering entire column */}
                  <th>
                    <Link
                      id="business-savings-cta"
                      className="position-relative stretched-link text-decoration-none text-white"
                      to="/business-banking/business-savings-account/small-business"
                    >
                      Business Savings
                      <Icon name="arrow-right" class="ml-2" />
                    </Link>
                  </th>
                  <th>
                    <Link
                      id="business-money-market-cta"
                      className="position-relative stretched-link text-decoration-none text-white"
                      to="/business-banking/business-savings-account/money-market-account"
                    >
                      Business Money Market
                      <Icon name="arrow-right" class="ml-2" />
                    </Link>
                  </th>
                  <th>
                    <Link
                      id="business-premium-money-market-cta"
                      className="position-relative stretched-link text-decoration-none text-white"
                      to="/business-banking/business-savings-account/high-yield-money-market-account"
                    >
                      Business Premium Money Market
                      <Icon name="arrow-right" class="ml-2" />
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    <Button id="cta-biz-savings-desktop" {...bizSavings} />
                  </td>
                  <td className="text-center">
                    <Button id="cta-biz-money-market-desktop" {...bizMoneyMarket} />
                  </td>
                  <td className="text-center">
                    <Button id="cta-biz-premium-desktop" {...bizPremiumMoneyMarket} />
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      If you need a simple, easy way to earn some interest on your extra cash, a Business Savings
                      account is a solid solution.
                    </strong>
                  </td>
                  <td>
                    <strong>
                      You need flexibility to manage your business effectively, and a Business Money Market delivers.
                    </strong>
                  </td>
                  <td>
                    <strong>Earn a higher interest rate with a Business Premium Money Market.</strong>
                  </td>
                </tr>
                <tr>
                  <td className="text-center">
                    <strong>$25 initial deposit</strong>
                  </td>
                  <td className="text-center">
                    <strong>$100 initial deposit</strong>
                  </td>
                  <td className="text-center">
                    <strong>$100 initial deposit</strong>
                  </td>
                </tr>
                <tr>
                  <td>Minimum to earn interest is $100</td>
                  <td>Minimum to earn interest is $1,000</td>
                  <td>
                    Minimum to earn interest is $1,000
                    <br />
                    Maximum $100,000 deposit per client
                  </td>
                </tr>
                <tr>
                  <td>$5 monthly fee, waived for balances over $200.</td>
                  <td>$12 monthly fee, waived for balances over $1,000.</td>
                  <td>$12 monthly fee, waived for balances over $1,000.</td>
                </tr>
                <tr>
                  <td>This is a variable rate account</td>
                  <td>This is a variable rate account</td>
                  <td>This is a variable rate account</td>
                </tr>
                <tr>
                  <td>
                    <Button id="business-savings-rates-cta-desktop" {...intereseRatesBtnData} />
                  </td>
                  <td>
                    <Button id="business-mm-rates-cta-desktop" {...intereseRatesBtnData} />
                  </td>
                  <td>
                    <Button id="business-premium-mm-rates-cta-desktop" {...intereseRatesBtnData} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* VISIBLE XS & SM */}
      <div className="mb-3 d-block d-md-none">
        {/* Business Savings */}
        <article id="m-business-savings" className="mb-3 border-bottom">
          <h2>
            <strong>Business</strong> Savings
          </h2>
          <div className={`${styles.mobileDetailsItem}`}>$5 monthly fee, waived for balances over $200.</div>
          <div
            id="business-savings-toggle"
            onClick={() => {
              setCollapseClass1(!collapseClass1);
            }}
            className={`${styles.mobileDetails} ${
              collapseClass1 ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-controls="business-savings-details"
            aria-label="Show/Hide Details"
            aria-expanded={collapseClass1}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapseClass1}>
            <div id="business-savings-details" className="collapse">
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>
                If you need a simple, easy way to earn some interest on your extra cash, a Business Savings account is a
                solid solution.
              </div>
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>$25 to open</div>
              <div className={styles.mobileDetailsItem}>Minimum to earn interest is $100</div>
              <div className={styles.mobileDetailsItem}>This is a variable rate account</div>
              <div className={`${styles.mobileDetailsItem} text-center`}>
                <Link to="/business-banking/business-savings-account/small-business" className="btn btn-link">
                  Learn More
                </Link>
              </div>
            </div>
          </Collapse>
          <Button id="cta-biz-savings-mobile" {...bizSavings} />
          <Button id="business-savings-rates-cta-mobile" {...intereseRatesBtnData} />
        </article>
        {/* Business Money Market */}
        <article id="m-money-market" className="mb-3 border-bottom">
          <h2>
            <strong>Business</strong> Money Market
          </h2>
          <div className={`${styles.mobileDetailsItem}`}>$12 monthly fee, waived for balances over $1,000.</div>
          <div
            id="money-market-toggle"
            onClick={() => {
              setCollapseClass2(!collapseClass2);
            }}
            className={`${styles.mobileDetails} ${
              collapseClass2 ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-controls="money-market-details"
            aria-label="Show/Hide Details"
            aria-expanded={collapseClass2}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapseClass2}>
            <div id="money-market-details" className="collapse">
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>
                You need flexibility to manage your business effectively, and a Business Money Market delivers.
              </div>
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>$100 initial deposit</div>
              <div className={styles.mobileDetailsItem}>Minimum to earn interest is $1,000</div>
              <div className={styles.mobileDetailsItem}>This is a variable rate account</div>
              <div className={`${styles.mobileDetailsItem} text-center`}>
                <Link to="/business-banking/business-savings-account/money-market-account" className="btn btn-link">
                  Learn More
                </Link>
              </div>
            </div>
          </Collapse>
          <Button id="cta-biz-money-market-mobile" {...bizMoneyMarket} />
          <Button id="business-mm-rates-cta-mobile" {...intereseRatesBtnData} />
        </article>
        {/* Business Premium Money Market*/}
        <article id="m-business-premium" className="mb-3 border-bottom">
          <h2>
            <strong>Business Premium</strong> Money Market
          </h2>
          <div className={`${styles.mobileDetailsItem}`}>$12 monthly fee, waived for balances over $1,000.</div>
          <div
            id="premium-money-market-toggle"
            onClick={() => {
              setCollapseClass3(!collapseClass3);
            }}
            className={`${styles.mobileDetails} ${
              collapseClass3 ? styles.mobileDetailsOpen : styles.mobileDetailsClosed
            }`}
            aria-controls="#premium-money-market-details"
            aria-label="Show/Hide Details"
            aria-expanded={collapseClass3}
            role="button"
          ></div>
          {/* "Show/Hide Details" via CSS ::after content */}
          <Collapse in={collapseClass3}>
            <div id="premium-money-market-details" className="collapse">
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>
                Earn a higher interest rate with a Business Premium Money Market.
              </div>
              <div className={`${styles.mobileDetailsItem} font-weight-bold`}>$100 initial deposit</div>
              <div className={styles.mobileDetailsItem}>
                Minimum to earn interest is $1,000
                <br />
                Maximum $100,000 deposit per client
              </div>
              <div className={styles.mobileDetailsItem}>This is a variable rate account</div>
              <div className={`${styles.mobileDetailsItem} text-center`}>
                <Link
                  to="/business-banking/business-savings-account/high-yield-money-market-account"
                  className="btn btn-link"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </Collapse>
          <Button id="cta-biz-premium-mobile" {...bizPremiumMoneyMarket} />
          <Button id="business-premium-mm-rates-cta-mobile" {...intereseRatesBtnData} />
        </article>
      </div>
    </section>
  );
};
export default BusinessSavingsOverview;
